import { createSlice } from '@reduxjs/toolkit';

interface InitialStateTypes {
    isAuthenticated: boolean;
    email: string;
}

const initialState: InitialStateTypes = {
    isAuthenticated: false,
    email: '',
};

const userAuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setIsAuthenticated: (state, action) => ({
            ...state,
            isAuthenticated: action.payload,
        }),
        setUserEmail: (state, action) => ({
            ...state,
            email: action.payload,
        }),
    },
});

export const selectIsAuthenticated = (state: any) => state.auth.isAuthenticated;

export const { setIsAuthenticated, setUserEmail } = userAuthSlice.actions;

export default userAuthSlice.reducer;
